* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  // background: rgb(26, 26, 26);
  background: #fff;
  font-family: 'Montserrat', sans-serif !important;
  height: 100%;
}

a {
  text-decoration: none !important;
}
a:hover{
  color: black !important;

}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
  transition: background-color 5000s ease-in-out 0s;
}

// Global color
.redColor{
  color:#db191c;
}
.grayColor{
  color: #77797c;
}

// Global text
.heading{
  font-size: 30px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}
.description{
  color: '#77797c' !important;
}

// Responsive
@media only screen and (max-width: 600px) {
  .heading{
    font-size: 20px !important;    
  }
  .description{
    font-size: 12px !important;
  }
}